<template>
  <dv-full-screen-container class="container">
    <div class="header">
      <div class="header__left">百川汇海 胜在齐心</div>
      <div class="header__title">
        <div>乡村振兴 数字</div>
        <div class="name">百胜</div>
      </div>
      <div class="header__right">
        翻页倒计时 <span class="fz-24">{{ curCountDown }}s</span>
      </div>
    </div>
    <div class="main f">
      <div class="main__left">
        <div class="main__left-title left">美图 TOP10</div>
        <div class="main__left-list">
          <div class="main__left-list_item f">
            <div class="index f-ai-c fz-14">1</div>
            <div class="itemImage">
              <img src="../assets/imgs/1.jpg" alt="" />
            </div>
            <div class="f-1 ml-17 f-d-c f-j-c">
              <div class="fz-20 fw-b">百年荔枝</div>
              <div class="mt-17 fz-16 f-j-sb f-ai-c">
                拍摄人:陈家明
                <div class="fz-16 f-ai-c">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/zan.png"
                    alt=""
                  />78
                </div>
              </div>
              <div class="mt-17 fz-16">拍摄日期: 2021年5月1日</div>
            </div>
          </div>
        </div>
      </div>
      <div class="main__middle f-fw f-1 ml-18">
        <div class="main__middle-up">
          <div class="main__middle-up-title left">两委发布</div>
          <div class="main__middle-list f-fw">
            <div class="main__middle-list_item">
              <div class="f">
                <div class="itemImage">
                  <img src="../assets/imgs/1.jpg" alt="" />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">百胜村开展2021年高素质农民培训</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/view.png"
                    alt=""
                  />169
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/zan.png"
                    alt=""
                  />287
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/share.png"
                    alt=""
                  />165
                </div>
              </div>
            </div>
            <div class="main__middle-list_item">
              <div class="f">
                <div class="itemImage">
                  <img src="../assets/imgs/1.jpg" alt="" />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">百胜村开展2021年高素质农民培训</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/view.png"
                    alt=""
                  />169
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/zan.png"
                    alt=""
                  />287
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/share.png"
                    alt=""
                  />165
                </div>
              </div>
            </div>
            <div class="main__middle-list_item">
              <div class="f">
                <div class="itemImage">
                  <img src="../assets/imgs/1.jpg" alt="" />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">百胜村开展2021年高素质农民培训</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/view.png"
                    alt=""
                  />169
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/zan.png"
                    alt=""
                  />287
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/share.png"
                    alt=""
                  />165
                </div>
              </div>
            </div>
            <div class="main__middle-list_item">
              <div class="f">
                <div class="itemImage">
                  <img src="../assets/imgs/1.jpg" alt="" />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">百胜村开展2021年高素质农民培训</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/view.png"
                    alt=""
                  />169
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/zan.png"
                    alt=""
                  />287
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/share.png"
                    alt=""
                  />165
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main__middle-bottom mt-18">
          <div class="main__middle-bottom-title left">交易广场</div>
          <div class="main__middle-list f-fw">
            <div class="main__middle-list_item">
              <div class="f">
                <div class="itemImage">
                  <img src="../assets/imgs/1.jpg" alt="" />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">百胜村开展2021年高素质农民培训</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/view.png"
                    alt=""
                  />169
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/zan.png"
                    alt=""
                  />287
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/share.png"
                    alt=""
                  />165
                </div>
              </div>
            </div>
            <div class="main__middle-list_item">
              <div class="f">
                <div class="itemImage">
                  <img src="../assets/imgs/1.jpg" alt="" />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">百胜村开展2021年高素质农民培训</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/view.png"
                    alt=""
                  />169
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/zan.png"
                    alt=""
                  />287
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/share.png"
                    alt=""
                  />165
                </div>
              </div>
            </div>
            <div class="main__middle-list_item">
              <div class="f">
                <div class="itemImage">
                  <img src="../assets/imgs/1.jpg" alt="" />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">百胜村开展2021年高素质农民培训</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/view.png"
                    alt=""
                  />169
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/zan.png"
                    alt=""
                  />287
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/share.png"
                    alt=""
                  />165
                </div>
              </div>
            </div>
            <div class="main__middle-list_item">
              <div class="f">
                <div class="itemImage">
                  <img src="../assets/imgs/1.jpg" alt="" />
                </div>
                <div class="f-1 pt-10 pb-10 ml-20 f-d-c f-j-sb">
                  <div class="fz-20">百胜村开展2021年高素质农民培训</div>
                  <div class="fz-16">2021年11月19日</div>
                </div>
              </div>
              <div class="shareBar f-j-sb f-ai-c">
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/view.png"
                    alt=""
                  />169
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/zan.png"
                    alt=""
                  />287
                </div>
                <div class="f-ai-c fz-16">
                  <img
                    class="icon mr-5"
                    src="../assets/imgs/share.png"
                    alt=""
                  />165
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main__right ml-18">
        <div class="main__right-title left">务工人才 TOP18</div>
        <div class="main__left-list">
          <div class="main__left-list_item f">
            <div class="index01 f-ai-c fz-14">1</div>
            <div class="itemImage01">
              <img src="../assets/imgs/1.jpg" alt="" />
            </div>
            <div class="f-1 ml-17 f-d-c f-j-sb">
              <div>
                <div class="fz-20 fw-b">李丽颖</div>
                <div class="mt-17 fz-16">职位：木匠</div>
              </div>
              <div class="fz-16 f-ai-c">
                <img class="icon mr-5" src="../assets/imgs/zan.png" alt="" />78
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dv-full-screen-container>
</template>

<script>
import { getConfig } from "@/api/village";
export default {
  data() {
    return {
      curCountDown: 60,
      interval: null,
      id: "",
      config:{},
    };
  },
  async mounted() {
    const id = this.$route.query.village_id || "6";
    this.id = id;
    this.getConfig(id);
  },
  methods: {
    setTimer(time) {
      let that = this;
      that.curCountDown = time || 60;
      this.interval = setInterval(function () {
        that.curCountDown = that.curCountDown - 1;
        if (that.curCountDown <= 0) {
          clearInterval(that.interval);
          that.$router.push({
            path: "village",
            query: { village_id: that.id },
          });
        }
      }, 1000);
    },
    async getConfig(id) {
      const { data } = await getConfig({
        village_id: id,
      });
      if (data.code === "200") {
        this.config = data.data;
        this.setTimer(data.data.countdown);
      }

      console.log("data", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #040b13;
  color: #ffffff;
}

.header {
  position: relative;
  width: 100%;
  height: 101px;
  background: url("../assets/imgs/big-title-bg.png") no-repeat;
  // background-size: cover;
  background-size: 100% 100%;
  padding: 15px 47px 0 103px;
  display: flex;
  justify-content: space-between;

  &__left {
    line-height: 33px;
    font-size: 24px;
    color: #56fefe;
  }

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 34px;
    font-weight: 400;
    color: #56f4fe;
    height: 53px;
    left: -56px;
    // letter-spacing: 1px;
    // background: linear-gradient(180deg, #56f4fe 0%, #a3ffcd 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;

    .name {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 126px;
      height: 53px;
      font-size: 32px;
      font-weight: 600;
      background: url("../assets/imgs/title-select.png") no-repeat;
      background-size: 126px 53px;
      margin-left: 10px;
      // color: #56f4fe;
      // line-height: 53px;
      // letter-spacing: 1px;
      // background: linear-gradient(180deg, #56f4fe 0%, #a3ffcd 100%);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
  }

  &__right {
    line-height: 33px;
    font-size: 24px;
    color: #56fefe;
  }
}

.main {
  padding: 25px;

  &__left {
    width: 515px;
    height: 928px;
    background: url("../assets/imgs/main-left.png") no-repeat;
    background-size: 100% 100%;
    padding: 20px 25px;

    &-title {
      font-size: 20px;
      color: #44ffff;
      line-height: 40px;
      height: 40px;
      margin: 0 auto;

      &.left {
        background: url("../assets/imgs/mt-title.png") no-repeat;
        background-size: 100% 3px;
        background-position: center bottom;
      }
    }

    &-list {
      padding-top: 17px;

      &_item {
        position: relative;
        margin-top: 30px;
      }

      &_item:first-child {
        margin-top: 0px;
      }
    }
  }

  .itemImage {
    width: 212px;
    height: 128px;
    overflow: hidden;
    border-radius: 4px;

    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  .shareBar {
    height: 30px;
    background: rgba(255, 255, 255, 0.1);
    margin-top: 14px;
    padding: 0 15px;
  }

  .icon {
    width: 22px;
    height: 22px;
  }

  .index {
    position: absolute;
    width: 52px;
    height: 36px;
    background: url("../assets/imgs/mt-index.png") no-repeat;
    background-size: 100% 100%;
    top: -5px;
    left: 5px;
    padding-left: 18px;
  }

  .index01 {
    position: absolute;
    width: 35px;
    height: 24px;
    background: url("../assets/imgs/wg-index.png") no-repeat;
    background-size: 100% 100%;
    top: 8px;
    left: -3px;
    padding-left: 12px;
  }

  .itemImage01 {
    width: 131px;
    height: 155px;
    overflow: hidden;
    border-radius: 4px;

    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  &__middle {
    width: 956px;

    &-list {
      &_item {
        width: 440px;
        margin-top: 20px;
      }

      &_item:nth-child(2n) {
        margin-left: 27px;
      }
    }
  }

  &__middle-up {
    width: 100%;
    height: 455px;
    background: url("../assets/imgs/middle-up.png") no-repeat;
    background-size: 100% 100%;
    padding: 20px;

    &-title {
      font-size: 20px;
      color: #44ffff;
      line-height: 40px;
      height: 40px;
      margin: 0 auto;

      &.left {
        background: url("../assets/imgs/lw-title.png") no-repeat;
        background-size: 100% 3px;
        background-position: center bottom;
      }
    }
  }

  &__middle-bottom {
    width: 100%;
    // width: 718px;
    height: 455px;
    background: url("../assets/imgs/middle-bottom.png") no-repeat;
    background-size: 100% 100%;
    padding: 20px;

    &-title {
      font-size: 20px;
      color: #44ffff;
      line-height: 40px;
      height: 40px;
      margin: 0 auto;

      &.left {
        background: url("../assets/imgs/lw-title.png") no-repeat;
        background-size: 100% 3px;
        background-position: center bottom;
      }
    }
  }

  &__right {
    width: 362px;
    height: 928px;
    background: url("../assets/imgs/main-right.png") no-repeat;
    background-size: 100% 100%;
    padding: 20px;

    &-title {
      font-size: 20px;
      color: #44ffff;
      line-height: 40px;
      height: 40px;
      margin: 0 auto;

      &.left {
        background: url("../assets/imgs/wg-title.png") no-repeat;
        background-size: 100% 3px;
        background-position: center bottom;
      }
    }
  }
}
</style>
